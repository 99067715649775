<template>
  <div class="g04-tender-stat">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-date-picker
            v-model="reportDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyyMMdd"
            style="width: 100%;">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleExport">导出Excel</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>
    <div class="spread-wrapper">
      <my-spread
        v-if="defaultDataList.length"
        ref="mySpread"
        :spread-info="spreadInfo"
        :default-data-list="defaultDataList"
        >
      </my-spread>
    </div>
  </div>
</template>

<script>
import g04Service from '@/services/g04Service'
import auth from '@/common/auth'
import MySpread from '@/components/Spread/MySpread'
import MySearchBar from '@/components/MySearchBar/MySearchBar'

export default {
  name: 'G04TenderStat',
  components: {
    MySearchBar,
    MySpread
  },
  data () {
    return {
      spreadInfo: {
        sheetName: '工程材料进度月统计汇总表',
        columns: [
          {
            label: '序号',
            dataKey: 'materialListCode',
            width: '50'
          },
          {
            label: '材料类别',
            dataKey: 'parentMaterialListName',
            width: 80,
            autoMerge: true
          },
          {
            label: '材料名称',
            dataKey: 'materialListName',
            width: 200,
            autoMerge: true
          },
          {
            label: '单位',
            dataKey: 'unit',
            width: 90
          },
          {
            label: '合同数量',
            children: [
              {
                label: '原合同数量',
                dataKey: 'num',
                width: 90
              },
              {
                label: '按施工图调整后数量',
                dataKey: 'num',
                width: 90
              },
              {
                label: '变更数量',
                width: 90
              },
              {
                label: '变更后数量',
                dataKey: 'num',
                width: 90
              }
            ]
          },
          {
            label: '本年计划',
            children: [
              {
                label: '工程量',
                dataKey: 'currentYearPlanNum',
                width: 100
              }
            ]
          },
          {
            label: '本月计划',
            children: [
              {
                label: '工程量',
                dataKey: 'currentMonthPlanNum',
                width: 100
              }
            ]
          },
          {
            label: '本月完成',
            children: [
              {
                label: '工程量',
                dataKey: 'currentMonthStatNum',
                width: 100
              },
              {
                label: '占月计划（%）',
                dataKey: 'currentMonthStatPercent',
                width: 100
              }
            ]
          },
          {
            label: '至月末本年度累计完成',
            children: [
              {
                label: '工程量',
                dataKey: 'totalMonthStatNum',
                width: 100
              },
              {
                label: '占年计划（%）',
                dataKey: 'totalMonthStatPercent',
                width: 100
              }
            ]
          },
          {
            label: '全部工程累计完成',
            children: [
              {
                label: '工程量',
                dataKey: 'totalStatNum',
                width: 100
              },
              {
                label: '占合同（%）',
                dataKey: 'totalStatPercent',
                width: 100
              }
            ]
          }
        ]
      },
      defaultDataList: [],
      materialList: [],
      tenderStatList: [],
      engineeringMap: {},
      otherTenderStats: [],
      reportDate: '',
      engineeringType: this.$route.query.engineeringType || '土建'
    }
  },
  watch: {
    reportDate (newVal) {
      this.getData()
    }
  },
  methods: {
    handleExport () {
      this.$refs.mySpread.exportExcel('标段工程材料进度月统计汇总表.xlsx')
    },
    generateMapData () {
      this.defaultDataList = this.tenderStatList.map((item, index) => {
        item.index = index
        return item
      })

      this.$loading().close()
    },
    getData () {
      this.$myLoading()
      this.tenderStatList = []
      this.defaultDataList = []
      if (!this.reportDate) {
        this.$loading().close()
        return
      }
      const year = parseInt(this.reportDate.substr(0, 4))
      const month = parseInt(this.reportDate.substr(4, 2))
      g04Service.getTenderStat(year, month, auth.getUserInfo().projectGuid, this.engineeringType)
        .then(res => {
          if (res.data.code === 1) {
            this.tenderStatList = res.data.data
            console.log(this.tenderStatList)

            this.generateMapData()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '加载失败，请重试！'
          })
        })
    }
  },
  created () {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.g04-tender-stat {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .spread-wrapper {
    flex: 1;
  }
}
</style>
